/* eslint react/no-multi-comp: 0, no-console: 0 */
import 'rc-slider/assets/index.css'
import React from 'react'
import Slider from 'rc-slider'

import {
  pointRemaining,
  getRandomInt,
  getTotalPoint,
  setFinaleStats
} from '../../../helpers'

const totalPointAllow = 300
const Range = Slider.Range

export class RcSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: ''
    }

    const randomNum = parseInt(getRandomInt(35, 55))

    const localPoints = localStorage.getItem(`value${this.props.id}`)
    if (!localPoints) {
      this.state = {
        value: [randomNum, 100]
      }
      localStorage.setItem(`value${this.props.id}`, randomNum)
    } else {
      this.state = {
        value: [randomNum, 100]
      }
    }
  }

  onSliderChange = value => {
    let val = parseInt(value[0])
    this.setState({
      value: [val, val + (totalPointAllow - getTotalPoint())]
    })

    // Relaie l'infos sur le nombre de point restant
    pointRemaining()

    // Store chacun des points individuellement
    localStorage.setItem(`value${this.props.id}`, val)

    // Set Max with for slider child
    document.querySelector(
      `#${this.props.id} .rc-slider-step`
    ).style.maxWidth = `${val}%`
  }

  onSliderAfterChange = () => {
    setFinaleStats()
  }

  componentDidMount() {
    const localPoints = localStorage.getItem(`value${this.props.id}`)
    if (localPoints) {
      this.setState({
        value: [parseInt(localPoints), 100]
      })
    }
  }

  render() {
    const { value } = this.state
    const { id, label } = this.props

    return (
      <div className="slider-ui">
        <div>{label}</div>
        <div>
          <div className="slider" id={id}>
            <Range
              defaultValue={[0, 100]}
              allowCross={false}
              value={value}
              onChange={this.onSliderChange}
              onAfterChange={this.onSliderAfterChange}
            />
            <div className="value">{value[0]}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default RcSlider
