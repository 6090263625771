import React, { Component, Fragment } from 'react'
import { withIntl } from 'gatsby-plugin-intl'
import SEO from '../components/seo'

import {
  dom,
  validateLocalStorage,
  initScripts,
  googleanalytic
} from '../helpers'

import { Icons, IconAdultOnly } from '../components/layout/icons'
import VideoPlayer from '../components/videoplayer'
import { initVideoScript } from '../components/init-videos'

import { Header, ExpCount } from '../components/layout/top'

import ActionsPanel from '../components/layout/actions'
import Character from '../components/layout/character'

// Steps
import StepOne from '../components/steps/step1'
import StepTwo from '../components/steps/step2'
import StepThree from '../components/steps/step3'
import StepFour from '../components/steps/step4'
import StepFive from '../components/steps/step5'
import StepSix from '../components/steps/step6'

// Style
import '../sass/styles.scss'

import { stepSounds } from '../helpers'

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      autoplayParam: false
    }
  }

  componentWillMount() {
    const getParam = variable => {
      if (typeof window !== 'undefined') {
        const vars = window.location.search.substring(1).split('&')
        for (let i = 0; i < vars.length; i++) {
          const pair = vars[i].split('=')
          if (pair[0] === variable) {
            return pair[1]
          }
        }
        return false
      }
    }
    const autoplayParam = !!+getParam('autoplay')       
    this.setState({
      autoplayParam
    })
  }

  componentDidMount() {
    googleanalytic()
    initVideoScript()
    validateLocalStorage()
    initScripts()

    // look for autoplay in url params
  
    // Prevent no images and buttons when back to home
    if (!this.state.autoplayParam) {
      document.documentElement.classList.remove('has-video', 'step-3-active')

      if (!stepSounds.playing()) {
        stepSounds.stop()
        stepSounds.play('welcome')
      }
    }

    // Init les points restant
    const showContent = setInterval(() => {
      dom('.inner-ctn').removeAttribute('style')
      document.documentElement.classList.add('selection')
      clearInterval(showContent)
    }, 250)

    // Prevent playing twice
  }

  componentWillUnmount() {
    stepSounds.stop()
    audioPack.bg.stop()
  }

  render() {
    const { intl } = this.props

    return (
      <Fragment>
        <SEO title={`${intl.formatMessage({ id: 'title' })}`} />
        <Icons />
        <main className="content" role="main">
          <VideoPlayer />

          <div className="inner-ctn" style={{ visibility: 'hidden' }}>
            <div className="top-ctn">
              <Header />
              <ExpCount />
            </div>

            <div className="ui-ctn">
              <ActionsPanel />
              <Character />
            </div>
          </div>

          <div className="steps-ctn">
            <StepOne isFirst={!this.state.autoplayParam} />
            <StepTwo />
            <StepThree isFirst={this.state.autoplayParam} />
            <StepFour />
            <StepFive />
            <StepSix />
          </div>

          <IconAdultOnly />
        </main>
      </Fragment>
    )
  }
}

export default withIntl(IndexPage)
