import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'

import { List } from './list'

import { getRandomInt, caps } from '../../../helpers'

const uri = 'images/characters/'

const data = [
  { ethnicity: `caucasian`, colors: `black`, img: `${uri}caucasian-black.jpg` },
  { ethnicity: `caucasian`, colors: `blond`, img: `${uri}caucasian-blond.jpg` },
  { ethnicity: `caucasian`, colors: `red`, img: `${uri}caucasian-red.jpg` },
  { ethnicity: `asian`, colors: `black`, img: `${uri}asian-black.jpg` },
  { ethnicity: `asian`, colors: `blond`, img: `${uri}asian-blond.jpg` },
  { ethnicity: `asian`, colors: `red`, img: `${uri}asian-red.jpg` },
  { ethnicity: `black`, colors: `black`, img: `${uri}black-black.jpg` },
  { ethnicity: `black`, colors: `blond`, img: `${uri}black-blond.jpg` },
  { ethnicity: `black`, colors: `red`, img: `${uri}black-red.jpg` }
]

const hairList = data.map(item => {
  const { ethnicity, colors, img } = item
  const randomNum = parseInt(getRandomInt(1, 9999))
  return (
    <li
      className={`char-opt ${ethnicity} hair-${colors}`}
      data-value={`{"hair":"${colors}"}`}
      data-layer={`{"eventAction":"Click","eventLabel":"Hair-${caps(
        colors
      )}","eventCategory":"UI Component"}`}
      key={`ukey_hair_${randomNum}`}
    >
      <div className={`char-img-ctn`}>
        <img src={img} alt={`${caps(ethnicity)} Hairs ${caps(colors)}`} />
      </div>
      <span>{caps(colors)}</span>
    </li>
  )
})

const HairColor = () => <List cat="hair-select">{hairList}</List>

export default injectIntl(HairColor)
