import React from 'react'

export const List = props => {
  return (
    <ol className="flex">
      <li className={`select-step${props.cat ? ' ' + props.cat : ''}`}>
        <ul className="char-opt-list">{props.children}</ul>
      </li>
    </ol>
  )
}

export default List
