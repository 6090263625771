import React from 'react'
import {
  injectIntl,
  FormattedMessage,
  FormattedHTMLMessage
} from 'gatsby-plugin-intl'
import Inner from '../layout/inner'
import { ModelAvatar, stepSounds } from '../../helpers'

const StepSix = () => {
  function handleClick() {
    window.cmd.player.mute()
    stepSounds.stop()
    stepSounds.play('onestep')
    document.documentElement.classList.add('direct-form')
  }
  let iframeUrl = ''
  return (
    <div className="step form-ctn ending ending-1" id={`Component_Step6`}>
      <Inner className={`congratulation maxwidth`}>
        <ModelAvatar className="ending" />
        <p className="step-text regular">
          <FormattedHTMLMessage id={`congratulation_message`} />
        </p>

        <p className="step-text actions-outs">
          <FormattedHTMLMessage id={`actions_outs_message`} />
        </p>

        <div className="btn-ctn">
          <button
            type="button"
            className="btn"
            data-layer='{"eventAction":"Click","eventLabel":"CTA-1","eventCategory":"UI Component"}'
            onClick={handleClick}
          >
            <FormattedMessage id={`continue`} />
          </button>
        </div>
      </Inner>
      <Inner className={`fill-form maxwidth`}>
        <ModelAvatar className="ending" />
        <p className="step-text">
          <FormattedHTMLMessage id={`one_step_fuck_her`} />
        </p>
        <iframe
          title="page-form-out"
          id="page-form"
          className="form"
          data-src={iframeUrl}
          allowtransparency="true"
          frameBorder="0"
          src={iframeUrl}
        />
      </Inner>
    </div>
  )
}

export default injectIntl(StepSix)
