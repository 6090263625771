import React from 'react'
import videojs from 'video.js'

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    window.videojs = videojs
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  render() {
    return (
      <div className="video-ctn">
        <div data-vjs-player>
          <video
            className="player video-js vjs-nofull"
            id="player"
            preload="auto"
            webkit-playsinline="true"
            playsInline
          />
        </div>
      </div>
    )
  }
}
