import React from 'react'

import {
  injectIntl,
  FormattedMessage,
  FormattedHTMLMessage
} from 'gatsby-plugin-intl'
import Inner from '../layout/inner'
import { initModel, stepSounds } from '../../helpers'

const StepOne = ({isFirst}) => {
  const handleClick = () => {
    initModel()
    stepSounds.stop()
    stepSounds.play('intensity')
    audioPack.bg.play()
  }
  return (
    <div className={`step step-1 intro${isFirst ? ' current' : ''}`} id={`Component_Step1`}>
      <Inner noborder className="bottom">
        <FormattedHTMLMessage id={`h1`} />
        <FormattedHTMLMessage id={`h2`} />
        <div className="btn-ctn">
          <button
            type="button"
            className="btn js-next-step"
            data-layer='{"eventAction":"Click","eventLabel":"Step-1-Continue","eventCategory":"UI Component"}'
            onClick={handleClick}
          >
            <FormattedMessage id={`play_now`} />
          </button>
        </div>
      </Inner>
    </div>
  )
}

export default injectIntl(StepOne)
