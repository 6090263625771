import React from 'react'
import {
  injectIntl,
  FormattedMessage,
  FormattedHTMLMessage
} from 'gatsby-plugin-intl'
import Inner from '../layout/inner'

const StepFive = () => (
  <div className="step change-step" id={`Component_Step5`}>
    <Inner className="maxwidth">
      <div className="step-text">
        <FormattedMessage id={`change_character_question`} />
      </div>
      <div className="step-text">
        <FormattedHTMLMessage id="save_game" />
      </div>
      <div className="btn-ctn">
        <button type="button" className="btn js-cancel-change">
          <FormattedMessage id={`back`} />
        </button>
        <button
          type="button"
          className="btn js-continue-change"
          data-layer='{"eventAction":"Click","eventLabel":"Change-CTA","eventCategory":"UI Component"}'
        >
          <FormattedMessage id={`continue`} />
        </button>
      </div>
    </Inner>
  </div>
)

export default injectIntl(StepFive)
