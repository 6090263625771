import React from 'react'
import { nameList, setFinaleStats } from '../../../helpers'

class NameForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: '' }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    localStorage.setItem('selected_model_name', event.target.value)
    this.setState({ value: event.target.value })
    setFinaleStats()
  }

  handleSubmit(event) {
    event.preventDefault()
  }

  componentDidMount() {
    const localName = localStorage.getItem('selected_model_name')
    const randomName = nameList[Math.floor(Math.random() * nameList.length)]
    if (localName) {
      this.setState({ value: localName })
    } else {
      this.setState({
        value: randomName
      })
    }
  }

  render() {
    const { value } = this.state
    return (
      <input
        type="text"
        value={value}
        onChange={this.handleChange}
        className="input-name"
      />
    )
  }
}

export default NameForm
