import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'

import { List } from './list'

import { getRandomInt, caps } from '../../../helpers'

const uri = 'images/characters'

const data = [
  { ethnicity: `caucasian`, img: `${uri}/caucasian-black.jpg` },
  { ethnicity: `asian`, img: `${uri}/asian-black.jpg` },
  { ethnicity: `black`, img: `${uri}/black-black.jpg` }
]

const ethnList = data.map(item => {
  const { ethnicity, img } = item
  const randomNum = parseInt(getRandomInt(1, 9999))
  return (
    <li
      className={`char-opt ethn-${ethnicity}`}
      data-value={`{"ethnicity":"${ethnicity}"}`}
      data-layer={`{"eventAction":"Click","eventLabel":"Ethnicity-${caps(
        ethnicity
      )}","eventCategory":"UI Component"}`}
      key={`ukey_ethnicity_${randomNum}`}
    >
      <div className={`char-img-ctn`}>
        <img src={img} alt={`${caps(ethnicity)}`} />
      </div>
      <span>{caps(ethnicity)}</span>
    </li>
  )
})

const Ethnicity = () => <List cat="ethnic-select">{ethnList}</List>

export default injectIntl(Ethnicity)
