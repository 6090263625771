import React from 'react'
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'
import Inner from '../layout/inner'
import { getLocal, dom, stepSounds, validateLocalStorage } from '../../helpers'

const StepTwo = () => {
  const handleClick = () => {
    validateLocalStorage()
    let bdyClass = document.documentElement.classList

    bdyClass.add('has-video', 'step-3-active')

    window.cmd.player.playScene('idle', false)
    stepSounds.stop()
    stepSounds.play('look')

    // let adultOnly = document.querySelector(".symbole");
    // adultOnly && adultOnly.remove();

    let valSkills = ['sucking', 'spanking', 'anal', 'feet']
    valSkills.forEach(obj => {
      let el = `#result_skills_points_${obj} `
      let getItemParse = parseInt(getLocal(`valueskills_points_${obj}`))
      dom(`${el}.progress`).style.width = `${getItemParse}%`
      dom(`${el}.stat-value`).textContent = `${getItemParse}`
    })

    if (document.documentElement.classList.contains('hide-video'))
      bdyClass.remove('hide-video')
  }

  return (
    <div className="step step-2" id={`Component_Step2`}>
      <Inner className="new-game bottom">
        <button
          type="button"
          className="btn js-next-step"
          data-layer='{"eventAction":"Click","eventLabel":"Step-2-Soft","eventCategory":"UI Component"}'
          onClick={handleClick}
        >
          <FormattedMessage id={`soft`} />
        </button>
        <button
          type="button"
          className="btn js-next-step"
          data-layer='{"eventAction":"Click","eventLabel":"Step-2-Hard","eventCategory":"UI Component"}'
          onClick={handleClick}
        >
          <FormattedMessage id={`hard`} />
        </button>
        <button
          type="button"
          className="btn js-next-step"
          data-layer='{"eventAction":"Click","eventLabel":"Step-2-Extreme","eventCategory":"UI Component"}'
          onClick={handleClick}
        >
          <FormattedMessage id={`extreme`} />
        </button>
      </Inner>
    </div>
  )
}

export default injectIntl(StepTwo)
