import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'
import {
  getRandomInt,
  pointRemaining,
  randomizeModel,
  dom,
  initModel,
  stepSounds,
  validateLocalStorage
} from '../../helpers'
import Inner from '../layout/inner'

import Ethnicity from './character/ethnicity'
import HairColor from './character/hair-color'
import BreastSize from './character/breast-size'
import { RcSlider } from './character/rcslider'
import NameForm from './character/name'

class StepThree extends Component {
  constructor(props) {
    super(props)

    this.state = {
      state_slider: '',
      state_name: '',
      slider_height: 0
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handleRandomize = this.handleRandomize.bind(this)
  }

  handleClick() {
    validateLocalStorage()
    document.documentElement.classList.remove('step-3-active')
    dom('#my_custom_name').textContent = localStorage.getItem(
      `selected_model_name`
    )
    cmd.player.vjs.muted(false)
    // Play the sound when the progress bar is full
    setTimeout(() => {
      stepSounds.stop()
      stepSounds.play('fantasy')
    }, 2500)
  }

  handleBack() {
    window.changeCurrent()
    window.prevStep()
    document.documentElement.classList.remove('step-3-active', 'has-video')
    document.documentElement.classList.add('hide-video')
  }

  handleRandomize() {
    randomizeModel()
    const a = setInterval(() => {
      this.setState({
        state_slider: getRandomInt(0, 9999),
        state_name: getRandomInt(10000, 19999)
      })
      pointRemaining()
      clearInterval(a)
    }, 50)
  }
  
  componentDidMount() {
    if (this.props.isFirst)  {
      const checkWindow= ()=> {
        const interval = setInterval(()=> {
          if (typeof window !== 'undefined' && typeof window.trackingData !== 'undefined') {
            clearInterval(interval)
            initModel()
            const bdyClass = document.documentElement.classList
            bdyClass.add('has-video', 'step-3-active')
            window.cmd.player.playScene('idle', false)
          }
        }, 500)
      }
      checkWindow()      
    }
  }

  render() {
    const { state_slider, state_name, slider_height } = this.state
    return (
      <div className={`step step-3 ${this.props.isFirst ? ' current' : ''}`} id={`Component_Step3`}>
        <Inner className="character-creation">
          <div className="step3-header-footer step3-header-center">
            <h3>
              <FormattedMessage id={`create_your_character`} />
            </h3>
            {/*  <div>
              <button
                type="button"
                onClick={this.handleRandomize}
                className="randomize"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 256 178.18"
                >
                  <title>Icon Randomize Model</title>
                  <path d="M100.53,94.63c-19,24.77-40.14,44.45-100,42.8L0,155.2c2.53.08,5,.08,7.37.08,59.82,0,84.9-21.62,104.22-46-3.33-4.26-6.48-8.52-9.59-12.74C101.51,95.91,101,95.29,100.53,94.63Z" />
                  <path d="M224,114.26l-12.56,12.56,10.5,10.5c-56.13-2.37-76.8-24-95.13-48.24,1.37-1.85,2.78-3.66,4.23-5.47,17.46-22,39-40.63,90.9-42.8L211.46,51.33,224,63.9l32-32L224,0,211.46,12.52,222,23.09c-56.84,2.35-83,23.18-101.91,45.83-1.51,1.77-2.93,3.54-4.31,5.36C95.76,47.81,71.57,22.87,7.23,22.87c-2.36,0-4.76,0-7.23.1L.5,40.72q3.51-.09,6.86-.09c58.94,0,77.81,22.12,97.35,48.45h0c.89,1.19,1.73,2.35,2.62,3.55.17.23.36.47.53.71,2.44,3.3,4.92,6.65,7.51,10l.44.58v0c19.24,24.64,44.87,48.65,106.22,51.17l-10.56,10.57L224,178.18l32-32Z" />
                </svg>
                <FormattedMessage id={`randomize`} />
              </button>
            </div> */}
          </div>

          <ol className="flex-selection">
            <li>
              <FormattedMessage id={`name`} />
            </li>
            <li>
              <NameForm key={state_name} />
            </li>

            <li>
              <FormattedMessage id={`ethnicity`} />
            </li>
            <li>
              <Ethnicity />
            </li>
            <li>
              <FormattedMessage id={`hair_color`} />
            </li>
            <li>
              <HairColor />
            </li>
            <li>
              <FormattedMessage id={`breast_size`} />
            </li>
            <li>
              <BreastSize />
            </li>
            <li className="skills">
              <FormattedMessage id={`skills`} />
            </li>
            <li className="skills">
              <div
                className="skills_slider"
                key={`uniqueKey_${state_slider}`}
                style={{ minHeight: slider_height }}
              >
                <div className="number-slider">
                  <FormattedMessage id={`remaining_points`} />{' '}
                  <span className="total-number-slider" />
                </div>
                <RcSlider id="skills_points_sucking" label="Sucking" />
                <RcSlider id="skills_points_spanking" label="Spanking" />
                <RcSlider id="skills_points_anal" label="Anal" />
                <RcSlider id="skills_points_feet" label="Feet" />
              </div>
            </li>
          </ol>
          <div className="step3-header-footer">
            <div>
              <button
                type="button"
                data-layer='{"eventAction":"Click","eventLabel":"Step-3-go-back","eventCategory":"UI Component"}'
                onClick={this.handleBack}
                className="back-to-main"
              >
                <FormattedMessage id={`back_to_main_menu`} />
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn js-next-step"
                data-layer='{"eventAction":"Click","eventLabel":"Step-3-Continue","eventCategory":"UI Component"}'
                onClick={this.handleClick}
              >
                <FormattedMessage id={`play`} />
                &nbsp;&nbsp;>
              </button>
            </div>
          </div>
        </Inner>
      </div>
    )
  }
}

export default injectIntl(StepThree)
