import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import { List } from './list'

import { getRandomInt, caps } from '../../../helpers'

const uri = 'images/characters'

const data = [
  {
    ethnicity: `caucasian`,
    size: `small`,
    img: `${uri}/caucasian-boobs-small.jpg`
  },
  {
    ethnicity: `caucasian`,
    size: `medium`,
    img: `${uri}/caucasian-boobs-medium.jpg`
  },
  {
    ethnicity: `caucasian`,
    size: `big`,
    img: `${uri}/caucasian-boobs-big.jpg`
  },
  { ethnicity: `asian`, size: `small`, img: `${uri}/asian-boobs-small.jpg` },
  { ethnicity: `asian`, size: `medium`, img: `${uri}/asian-boobs-medium.jpg` },
  { ethnicity: `asian`, size: `big`, img: `${uri}/asian-boobs-big.jpg` },
  { ethnicity: `black`, size: `small`, img: `${uri}/black-boobs-small.jpg` },
  { ethnicity: `black`, size: `medium`, img: `${uri}/black-boobs-medium.jpg` },
  { ethnicity: `black`, size: `big`, img: `${uri}/black-boobs-big.jpg` }
]

const breastList = data.map(item => {
  const { ethnicity, size, img } = item
  const randomNum = parseInt(getRandomInt(1, 9999))
  return (
    <li
      className={`char-opt ${ethnicity} boobs-${size}`}
      data-value={`{"boobs":"${size}"}`}
      data-layer={`{"eventAction":"Click","eventLabel":"Tits-${caps(
        size
      )}","eventCategory":"UI Component"}`}
      key={`ukey_breast_${randomNum}`}
    >
      <div className={`char-img-ctn`}>
        <img src={img} alt={`${caps(ethnicity)} Boobs ${caps(size)}`} />
      </div>
      <span>{caps(size)}</span>
    </li>
  )
})

const BreastSize = () => <List cat="boob-select">{breastList}</List>

export default injectIntl(BreastSize)
