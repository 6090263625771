import React from 'react'
import {
  injectIntl,
  FormattedMessage,
  FormattedHTMLMessage
} from 'gatsby-plugin-intl'
import Inner from '../layout/inner'
import { domAll, stepSounds, validateLocalStorage } from '../../helpers'

const tipsText = [
  `<h3>TIPS:</h3><p>This is a preview, you get more fantasies with a profile!</p>`,
  `<h3>TIPS:</h3><p>Make your sex doll do what you've always dreamt of!</p>`
]
const tips = tipsText[Math.floor(Math.random() * tipsText.length)]

const StepFour = () => {
  const handleClick = () => {
    validateLocalStorage()
    document.documentElement.classList.add('step-3-done')
    const ethn = localStorage.getItem('ethnicity')
    const hair = localStorage.getItem('hair')
    const boobs = localStorage.getItem('boobs')

    domAll('.char-img').forEach(el => {
      el.src = `images/characters/${ethn}-${hair}.jpg`
    })

    window.cmd.loadData(`${ethn}-${hair}-${boobs}`)
    window.cmd.player.playScene('idle')
  }
  return (
    <div className="step step-4 step-compat" id={`Component_Step4`}>
      <div className="compatibility-check">
        <span className="loading-wait">
          <FormattedMessage id={`loading_game`} />
        </span>
        <Inner noborder>
          <div className="progress-ctn progress-compat">
            <div className="progress" />
          </div>
          <div className="progress-percent">
            <span className="value" id="progress-value">
              0%
            </span>
          </div>
        </Inner>
        <div className="loading-tips">
          <div dangerouslySetInnerHTML={{ __html: tips }} />
        </div>
      </div>
      <Inner className="is-compatible">
        <p className="step-text">
          <FormattedHTMLMessage id={`pick_fantasy`} />
        </p>
        <div className="btn-ctn">
          <button
            type="button"
            className="btn js-next-step"
            data-layer='{"eventAction":"Click","eventLabel":"Step-4-Continue","eventCategory":"UI Component"}'
            onClick={handleClick}
          >
            <FormattedMessage id={`continue`} />
          </button>
        </div>
      </Inner>
    </div>
  )
}

export default injectIntl(StepFour)
