import { stepSounds } from '../helpers'
export const initVideoScript = () => {
  function shuffle(array) {
    var currentIndex = array.length
    var temporaryValue, randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }
    return array
  }

  function returnOrientation() {
    var ratio = document.body.offsetHeight / document.body.offsetWidth
    var ratioName = 'landscape'

    if (ratio > 1.25) {
      ratioName = 'portrait'
    }

    return ratioName
  }

  function Cmd(params) {
    var self = this
    self.params = params ? params : {}

    self._init()
  }

  Cmd.prototype = {
    _init: function() {
      var self = this

      self.data =
        typeof self.params.data !== 'undefined' ? self.params.data : {}
      self.id =
        typeof self.params.id !== 'undefined'
          ? self.params.id
          : Object.keys(self.data)[0]
      self.muted =
        typeof self.params.muted !== 'undefined' ? self.params.muted : false
      self.playOnDisabled =
        typeof self.params.playOnDisabled !== 'undefined'
          ? self.params.playOnDisabled
          : true
      self.startAction =
        typeof self.params.startAction !== 'undefined'
          ? self.params.startAction
          : false
      self.firstAction =
        typeof self.params.firstAction === 'string'
          ? self.params.firstAction
          : 'idle'
      self.poster =
        typeof self.params.poster === 'string' ? self.params.poster : false

      // Targets

      self.playerTarget =
        typeof self.params.playerTarget !== 'undefined'
          ? self.params.playerTarget
          : ''
      self.actionsTarget =
        typeof self.params.actionsTarget !== 'undefined'
          ? self.params.actionsTarget
          : ''
      self.walletTarget =
        typeof self.params.walletTarget !== 'undefined'
          ? self.params.walletTarget
          : false

      self.actionPanel = document.getElementById(self.actionsTarget)
      self.actionClone = self.actionPanel
        .querySelector('.action')
        .cloneNode(true)
      self.actions = []

      // Events
      var events =
        typeof self.params.events !== 'undefined' ? self.params.events : {}
      self._oninsufficient =
        typeof events.oninsufficient !== 'undefined'
          ? [{ fn: events.oninsufficient }]
          : {}
      self._ondisabled =
        typeof events.ondisabled !== 'undefined'
          ? [{ fn: events.ondisabled }]
          : {}
      self._onblocked =
        typeof events.onblocked !== 'undefined'
          ? [{ fn: events.onblocked }]
          : {}
      self._onplay =
        typeof events.onplay !== 'undefined' ? [{ fn: events.onplay }] : {}
      self._onended =
        typeof events.onended !== 'undefined' ? [{ fn: events.onended }] : {}
      self._onready =
        typeof events.onready !== 'undefined' ? [{ fn: events.onready }] : {}

      if (!self.player && !self.actionPanel) {
        return false
      }

      self.loadData(self.id)
      self.generateActions()
      if (self.walletTarget) {
        self.wallet = new Wallet(
          self,
          document.getElementById(self.walletTarget)
        )
      }

      self.player = new Player(self, document.getElementById(self.playerTarget))

      self._emit('ready', self)
    },

    /**
     * Emit an event
     * @param  {String} event - The event to be triggered
     * @param  {String/Object} info - The information to be broadcasted to the event.
     */

    _emit: function(event, info) {
      var self = this
      var events = self['_on' + event]

      for (var i = 0; i < events.length; i++) {
        setTimeout(
          function(fn) {
            fn.call(this, info)
          }.bind(self, events[i].fn),
          0
        )
      }

      return self
    },

    loadData: function(id) {
      var self = this
      self.id = id
      self.currentData = self.data[self.id]
    },

    manageScene: function(data) {
      var self = this

      if (data.cost === 'na') {
        if (self.playOnDisabled) {
          self.player.playScene(data.name)
        }
        self._emit('disabled')
      } else {
        if (self.wallet && data.cost) {
          if (self.wallet._verifyGoldCount(data.cost)) {
            self.player.playScene(data.name)
          } else {
            self._emit('insufficient')
          }
        } else {
          // TODO : Count de videos?
          stepSounds.stop()
          self.player.playScene(data.name)
        }
      }
    },

    generateActions: function() {
      var self = this
      var actionKeys = shuffle(Object.keys(self.currentData.actions))
      self.actionPanel.innerHTML = ''

      if (self.startAction) {
        var tmp = actionKeys[self.startAction.index]
        var oldIndex = actionKeys.indexOf(self.startAction.name)
        actionKeys[self.startAction.index] = self.startAction.name
        actionKeys[oldIndex] = tmp
      }

      for (var actionName in actionKeys) {
        actionName = actionKeys[actionName]

        self.actions[actionName] = new Action(
          self,
          actionName,
          self.currentData.actions[actionName]
        )
        self.actionPanel.appendChild(self.actions[actionName].elem)
      }
    },

    _emptyActions: function() {
      var self = this
      while (self.actionPanel.firstChild) {
        self.actionPanel.removeChild(self.actionPanel.firstChild)
      }
    }
  }

  function Player(cmd, elem) {
    var self = this
    self._parent = cmd
    self.elem = elem

    self._init()
  }

  Player.prototype = {
    _init: function(name) {
      var self = this
      self.vjs = window.videojs(self.elem)
      var data = self._parent.currentData
      var keyScenes = data.keys

      self.vjs.on('ended', function() {
        var idle = 'idle'
        if (keyScenes.indexOf('idle2') > -1 && self.currentScene === 'toggle') {
          // TODO That's a whole lot of presuming stuff there
          idle = 'idle2'
        }
        self.playScene(idle, true)
        self._parent._emit('ended', name)
      })

      self.vjs.muted(true)
    },

    toggleMute: function() {
      var self = this
      self._parent.muted = !self._parent.muted
      self.vjs.muted(self._parent.muted)
    },

    mute: function() {
      var self = this
      self._parent.muted = true
      self.vjs.muted(self._parent.muted)
    },

    _onBlockedAutoplay: function() {
      var self = this
      var data = self._parent.currentData
      var keyScenes = data.keys
      if (self._parent.poster && self.vjs.paused()) {
        self.vjs.poster(self._parent.poster)
      }

      var unmuteOnClick = function() {
        self.vjs.muted(self._parent.muted)
        if (self.vjs.paused()) {
          self.vjs.play()
        }

        self.vjs.poster('')

        document.documentElement.removeEventListener(
          'click',
          unmuteOnClick,
          false
        )
      }

      if (!self._parent.muted) {
        self.vjs.muted(true)

        document.documentElement.addEventListener('click', unmuteOnClick, false)
      }

      if (keyScenes.indexOf('idle2') > -1) {
        self.playScene('idle2', true)
      } else if (keyScenes.indexOf('idle') > -1) {
        self.playScene('idle', true)
      }

      self._parent._emit('blocked')
    },

    _determineSize: function(scene) {
      var keys = Object.keys(scene)
      var width = document.body.offsetWidth
      var size = '1920x1080'

      if (keys.indexOf('1280x720') > -1 && keys.indexOf('1920x1080') === -1) {
        // 1280 seeked ONLY if 1920 is not existing
        size = '1280x720'
      }

      if (width <= 854 && keys.indexOf('854x480') > -1) {
        size = '854x480'
      }

      if (
        width <= 854 &&
        keys.indexOf('720x1280') > -1 &&
        returnOrientation() === 'portrait'
      ) {
        size = '720x1280'
      }

      return size
    },

    playScene: function(name, loop, customSrc) {
      var self = this
      var data = self._parent.currentData
      var src = typeof customSrc === 'object' ? customSrc : data.src[name]
      self.currentScene = name

      loop = typeof loop !== 'undefined' ? loop : false

      self.vjs.loop(loop)

      if (src) {
        var size = self._determineSize(src)
        src = src[size]
        self.vjs.src(src)
        self.vjs.play()
        self._parent._emit('play', name)
      }
    }
  }

  function Action(cmd, name, data) {
    var self = this
    self._parent = cmd
    self.data = data
    self.name = name

    self._init()
  }

  Action.prototype = {
    _init: function() {
      var self = this
      self.elem = self._createElem()
    },

    _createElem: function() {
      var self = this
      var listElem

      if (self._parent.actionClone) {
        listElem = self._parent.actionClone.cloneNode(true)
      } else {
        listElem = document.createElement('li')
      }

      listElem.classList.add(self.name)
      var svgElem = document.querySelector('.icon-' + self.name).cloneNode(true)

      svgElem.classList.add('a-icon')

      var aIconElem = listElem.querySelector('.a-icon')

      if (aIconElem) {
        aIconElem.parentNode.replaceChild(svgElem, aIconElem)
      } else {
        listElem.appendChild(svgElem)
      }

      listElem.classList.add('action')

      var infoElem = listElem.querySelector('.a-info')

      if (infoElem) {
        infoElem.innerText = self.data.info
      }

      if (self.data.cost === 'na') {
        listElem.classList.add('disabled')
      } else {
        var costElem = listElem.querySelector('.a-cost')
        if (costElem) {
          costElem.innerText = self.data.cost
        }
      }

      var layer = {
        eventLabel:
          'Action-' + self.name.charAt(0).toUpperCase() + self.name.slice(1),
        eventAction: 'Click',
        eventCategory: 'UI Component'
      }

      listElem.dataset.data = JSON.stringify(self.data)
      listElem.dataset.layer = JSON.stringify(layer)

      return listElem
    }
  }

  function Wallet(cmd, elem) {
    var self = this
    self._parent = cmd
    self.elem = elem
    self.paidInterval = {}
    self._init()
  }

  Wallet.prototype = {
    _init: function() {
      var self = this
      self.bank = parseInt(self.elem.innerText)
    },

    _verifyGoldCount: function(cost) {
      var self = this
      cost = parseInt(cost)
      var willSubstract = cost <= self.bank

      if (willSubstract) {
        self._subtractCost(cost)
      }

      return willSubstract
    },

    _subtractCost: function(cost) {
      var self = this
      clearTimeout(self.paidInterval)
      var dropCount = self.bank
      self.bank -= cost
      var parentWallet = self.elem.parentNode
      parentWallet.classList.add('spend')
      setTimeout(function() {
        parentWallet.classList.remove('spend')
      }, 1000)

      self.paidInterval = setInterval(function() {
        dropCount = dropCount - 5

        if (dropCount >= self.bank) {
          self.elem.innerText = dropCount
        }
      }, 30)
    }
  }

  window.Cmd = Cmd
}
